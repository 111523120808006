function setLableFloat(name) {

    // jQuery(document).on('DOMSubtreeModified', 'kendo-combobox', function () {
    //     var input = $(this).children().children().children()[0];
    //     $(this).parents('.k-textbox-container').toggleClass('k-state-focused', (input.value.length > 0 || input.textContent.length > 0))
    // });

    // jQuery(document).on('focus blur', '.k-input', function (e) {
    //     $(this).parents('.k-textbox-container')
    //         .toggleClass('k-state-focused', (e.type === 'focusin'
    //             || this.value.length > 0 || this.textContent.length > 0))
    // }).trigger('blur');

    // // jQuery(document).on('focus', 'kendo-dropdownlist', function (e) {
    // //     e.target.parentElement.parentElement.classList.remove('k-state-empty');
    // // });

    // jQuery(document).on('focus blur', 'kendo-dropdownlist', function (e) {
    //     // if (e.target.children[0].textContent.trim() == '') {
    //     //     e.target.parentElement.parentElement.classList.add('k-state-empty');
    //     // }
    //     // else {
    //     //     e.target.parentElement.parentElement.classList.remove('k-state-empty');
    //     // }
    //     var input = $(this).children().children()[0];
    //     $(this).parents('.k-textbox-container').toggleClass('k-state-focused', (e.type === 'focusin' || input.textContent.trim().length > 0))

    // }).trigger('blur');


    // jQuery(document).on('DOMSubtreeModified', 'kendo-dropdownlist', function (e) {
    //     var input = $(this).children().children()[0];
    //     $(this).parents('.k-textbox-container').toggleClass('k-state-focused', input.textContent.trim().length > 0)
    // });
    // jQuery(document).on('DOMSubtreeModified', 'kendo-multiselect', function (e) {
    //     var input = $(this).children().children()[0].children[0].children;
    //     $(this).parents('.k-textbox-container').toggleClass('k-state-focused', input.length > 0)
    // });

    // // jQuery(document).on('focus', 'kendo-multiselect', function (e) {
    // //     e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // // });

    // jQuery(document).on('focus blur', 'kendo-multiselect', function (e) {
    //     var input = $(this).children().children()[0].children[0].children;
    //     $(this).parents('.k-textbox-container').toggleClass('k-state-focused', (e.type === 'focusin' || input.length > 0))
    // });
    // //  jQuery(document).on('focus', 'kendo-dropdownlist', function (e) {
    // //      e.target.parentElement.parentElement.classList.remove('k-state-empty');
    // //  });
    // //  jQuery(document).on('blur', 'kendo-dropdownlist', function (e) {
    // //      if (e.target.children[0].textContent.trim() == '') {
    // //          e.target.parentElement.parentElement.classList.add('k-state-empty');
    // //      }
    // //      else {
    // //          e.target.parentElement.parentElement.classList.remove('k-state-empty');
    // //      }
    // //  });
    // //  jQuery(document).on('DOMSubtreeModified', '.k-input', function (e) {
    // //      if (e.target.textContent.trim() == '') {
    // //          e.target.parentElement.parentElement.parentElement.classList.add('k-state-empty');
    // //      }
    // //      else {
    // //          e.target.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // //      }
    // //  });
    // //  jQuery(document).on('DOMSubtreeModified', 'kendo-combobox', function (e) {
    // //      if (e.target.value && e.target.value.trim() == '') {
    // //          e.target.parentElement.parentElement.parentElement.classList.add('k-state-empty');

    // //      }
    // //      else {
    // //          e.target.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // //      }
    // //  	if(e.target.parentElement.parentElement.parentElement.parentElement.classList.contains('k-textbox-container'))
    // //  	{
    // //      if (!e.target.value || e.target.value.trim() == '') {
    // //          e.target.parentElement.parentElement.parentElement.parentElement.classList.add('k-state-empty');
    // //      }
    // //      else {
    // //          e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // //      }
    // //  	}
    // //  });


    // //  jQuery(document).on('focus', 'kendo-combobox', function (e) {
    // //  	if(e.target.parentElement.parentElement.parentElement.parentElement.classList.contains('k-textbox-container'))
    // //      e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // //  });
    // //  jQuery(document).on('blur', 'kendo-combobox', function (e) {
    // //  	if(e.target.parentElement.parentElement.parentElement.parentElement.classList.contains('k-textbox-container'))
    // //  	{
    // //      if (!e.target.value || e.target.value.trim() == '') {
    // //          e.target.parentElement.parentElement.parentElement.parentElement.classList.add('k-state-empty');
    // //      }
    // //      else {
    // //          e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // //      }
    // //  	}
    // //  }); 
    // //  jQuery('.k-clear-value').on('click', function (e) {
    // //  	if(e.target.parentElement.parentElement.parentElement.classList.contains('k-combobox'))
    // //  	{   
    // //  		e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('k-state-empty');

    // //  	}
    // //  });
    // //  jQuery(document).on('DOMSubtreeModified', '.k-reset', function (e) {
    // //      if (e.target.childElementCount <= 0) {
    // //          e.target.parentElement.parentElement.parentElement.parentElement.classList.add('k-state-empty');
    // //      }
    // //      else {
    // //          e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('k-state-empty');
    // //      }
    // //  });
}
